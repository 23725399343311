import { PaperClipIcon } from "@heroicons/react/20/solid";

const json = {
  title: "아카라라이프 비즈니스 솔루션",
  description: "마이워크스페이스 강남역 타워점",
  logo: {
    src: "attachments/myworkspace.png",
  },
  shortcuts: [
    {
      name: "마이워크스페이스 강남역 타워점 스크린",
      link: "https://graph-kr.aqara.com/#/preview?panelGroupId=pcg.sc.1166040282611806208&pj=198dc2f1893db72.1063016242834690049&entry=1&auth=",
    },
    {
      name: "Aqara Graph",
      link: "https://graph-kr.aqara.com/#/screen-home",
    },
    {
      name: "Aqara IoT Application Platform",
      link: "https://iiap-kr.aqara.com/#/dashboard",
    },
    {
      name: "키오스크 예약 관리",
      link: "https://admin.office.carepia.co.kr/desk",
    },
    {
      name: "카드 매출 관리",
      link: "https://www.easyshop.co.kr/",
    },
    {
      name: "QR 출입 관리",
      link: "https://biostar.office.carepia.co.kr/",
    },
  ],
  attachments: [
    {
      name: "키오스크",
      link: "attachments/kiosk-user-manual.pdf",
    },
    {
      name: "키오스크 예약 관리",
      link: "attachments/admin-user-manual.pdf",
    },
    {
      name: "카드 매출 관리",
      link: "attachments/easyshop-user-manual.pdf",
    },
    {
      name: "QR 출입 관리",
      link: "attachments/biostar-user-manual.pdf",
    },
    {
      name: "브라우저 캐시 삭제 방법",
      link: "attachments/browser-delete-manual.pdf",
    },
  ],
};

function App() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl mt-8 p-8 sm:p-12">
        <div className="text-center">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            {json.title}
          </h1>
        </div>
        <img className="mx-auto h-9 mt-4" src={json.logo.src} alt="" />
      </div>
      <div className="mx-auto max-w-3xl py-6 px-4 sm:px-6">
        <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
          {json.shortcuts.map((item) => (
            <ShortcutRow key={item.name} name={item.name} link={item.link} />
          ))}
        </ul>
        <ul className="divide-y divide-gray-100 rounded-md border border-gray-200 mt-8">
          {json.attachments.map((item) => (
            <AttachmentRow key={item.name} name={item.name} link={item.link} />
          ))}
        </ul>
      </div>

      <footer className="bg-white p-4 sm:p-8">
        <div className="mx-auto max-w-screen-xl text-center">
          <img
            className="mx-auto h-12 mb-4"
            src="attachments/aqaralife.png"
            alt=""
          />
          <span className="text-sm text-gray-500 sm:text-center">
            © 2023-2024{" "}
            <a href="https://aqaralife.kr/" className="hover:underline">
              AqaraLife
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}

export default App;

function ShortcutRow({ name, link }: { name: string; link: string }) {
  return (
    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
      <div className="flex w-0 flex-1 items-center">
        <span className="truncate font-medium">{name}</span>
      </div>
      <div className="ml-4 flex-shrink-0">
        <a
          href={link}
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          바로가기
        </a>
      </div>
    </li>
  );
}

function AttachmentRow({ name, link }: { name: string; link: string }) {
  return (
    <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
      <div className="flex w-0 flex-1 items-center">
        <PaperClipIcon
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
        <div className="ml-4 flex min-w-0 flex-1 gap-2">
          <span className="truncate font-medium">{name}</span>
        </div>
      </div>
      <div className="ml-4 flex-shrink-0">
        <a
          href={link}
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          다운로드
        </a>
      </div>
    </li>
  );
}
